
import { AreaData, userService } from "@/service";
import { computed, defineComponent, reactive } from "vue";
import { Dialog, ImagePreview } from "vant";
import { useRouter } from "vue-router";
import { RouterName } from "@/router";

interface UfFileType {
  file_name: string;
  file_size: string;
  photo_file: string;
  photo_thumb_file: string;
  photo_type: string;
  upload_time: string;
  user_id: string;
  id: string;
}

interface Certificate {
  photo_id: string;
  photo_id_array: UfFileType;
}

const getDefaultForm = () => ({
  state: 0,
  state_name: "",
  type: "",
  type_name: "",
  name: "",
  gender_name: "",
  mobile: "",
  email: "",
  department_name: "",
  duty_name: "",
  subgroup_name: "",
  id_card: "",
  start_time_name: "",
  end_time_name: "",
  desc: "",
  stores_name: "",
  factory_name: "",
  stores_type_name: "",
  stores_sub_name: "",
  place_name: "",
  social_credit: "",
  license_start_time_name: "",
  license_end_time_name: "",
  owner_name: "",
  address: "",
  contact_name: "",
  contact_phone: "",
  province_detail: {} as AreaData,
  city_array: {} as AreaData,
  county_detail: {} as AreaData,
  positive_array: {} as UfFileType,
  back_array: {} as UfFileType,
  license_array: {} as UfFileType,
  certificate: [] as Certificate[]
});
export default defineComponent({
  name: "AuthenticationDetail",
  setup() {
    const router = useRouter();
    const detail = reactive(getDefaultForm());
    const headerImg = computed(() =>
      detail.state == 2
        ? require("@/assets/icons/authentication-ico01@2x.png")
        : require("@/assets/icons/authentication-ico02@2x.png")
    );
    const headerMessage = computed(() => (detail.state == 2 ? "认证申请已提交，等待管理员审核" : detail.desc));
    const detailType = computed(() => {
      const o = {
        "1": "Staff",
        "3": "Stores",
        "7": "Factory"
      };
      return o[detail.type];
    });

    const getDateil = async () => {
      const { data } = await userService.authenticationDetail();
      Object.assign(detail, data.data);
    };

    const cancelApply = async () => {
      if (detail.state == 3) {
        await router.push({
          name: RouterName.AUTHENTICATION
        });
        return;
      }
      try {
        await Dialog.confirm({
          message: "确定要取消申请吗"
        });
        await userService.cancelApply();
        await router.push({
          name: RouterName.MY
        });
      } catch (error) {}
    };

    getDateil();

    return {
      ImagePreview,
      headerImg,
      headerMessage,
      detail,
      cancelApply,
      detailType
    };
  }
});
